<template>
  <div id="InCS" class=" items-center justify-center inset-0 ...">
    <table
      aria-describedby="Future Surgeries"
      style="width: 70%; background-color: white;margin: auto!important"
      class="text-center shadow-md w-2/3 m-3 bg-white"
    >
      <tr class="HeaderTableColor">
        
         <th id="Package" class="text-2xl font-bold h-24 text-center">
          {{ $t("Package") }}
        </th>

      
        <th id="Date" class="text-2xl font-bold h-24 text-center">
          {{ $t("Date") }}
        </th>
        

          <th id="NumberOfPassenger" class="text-2xl font-bold h-24 text-center">
          
          {{ $t("HowManyPassengers") }}
        </th>

          <th id="NumberOfBeneficiary" class="text-2xl font-bold h-24 text-center">
            <div class="MyPopClose">
            <feather-icon
              icon="XIcon"
              svgClasses="w-7 h-7 hover:text-primary stroke-current"
              @click.stop="$emit('closePop')"
            />
          </div>
          {{ $t("NumberOfBeneficiary") }}
        </th>

      </tr>
      <tr
        class="text-xl h-24"
        v-for="(item, index) in ReservatedPackages"
        :key="index"
      >
      
        <td>
           <div @click="GoPackageDetail(item.ID)">
            <p v-if="item.MedicalPackage">
                <u class="text-primary text-xl" style="cursor: pointer">{{
                      item.MedicalPackage.Name
                }}</u>
            </p>
          </div>

          
        </td>
        <td>
          <div >
              <p v-if="item.PackageStartDate"> {{ item.PackageStartDate.split("T")[0] }} </p>
              <p v-else-if="item.PreferrdDateFrom&&item.PreferrdDateTo"> {{ item.PreferrdDateFrom.split("T")[0] +'-'+item.PreferrdDateTo.split("T")[0] }} </p>

          </div>
        </td>
        <td>
          <p v-if="item.MedicalPackage">
            {{ item.NumberOfCompanions }}
          </p>
      </td>
       <td>
          <p v-if="item.MedicalPackage">
            {{ item.NumberOfBeneficiary }}
          </p>
      </td>
      </tr>
     
  <tr v-if="ReservatedPackages.length==0">
        <td></td>
            
        <td><h3  class="w-full sm:w-auto text-center text-primary mt-5 mb-5">{{$t("NoDataToshow")}}</h3></td>
        <td></td>

          </tr>
      <tr>
        <vs-button
          v-if="viewHistory"
          class="mr-2 mt-5 mb-5 customizer-btn rounded-lg text-sm"
          style="width: 95% !important; margin-left: 80%"
          icon-pack="feather"
          @click="getOldReservedPackages()"
          icon="icon-arrow-right"
          :icon-after="true"
        >
          {{ $t("History") }}</vs-button
        >
      </tr>
    </table>
  </div>
</template>
<script>
import moduleReservedPackage from "@/store/reservedPackage/moduleReservedPackage.js";
import { domain } from "@/gloabelConstant.js";

export default {
  components: {},
  data() {
    return {
      viewHistory:true,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
      search: {},
    };
  },
  computed: {
    ReservatedPackages() {
      return this.$store.state.ReservatedPackage.reservedPackages;
    },
  },
  methods: {
    
    GoPackageDetail(ID) {
      debugger
      this.$router.push("/ReservedPackageDetail/"+ID );
    },
    getOldReservedPackages() {
      this.viewHistory=false;
        this.search.StatusID = 2; 
      this.$vs.loading();
      this.$store
        .dispatch("ReservatedPackage/SearchReservedPackage", this.search)
        .then(() => {
          this.$vs.loading.close();
                    if(this.ReservatedPackages==null||this.ReservatedPackages.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
    getReservatedPackages(id) {
   
      this.search.PatientId = id;
      this.search.StatusID = 1; 
      this.$vs.loading();
      this.$store
        .dispatch("ReservatedPackage/SearchReservedPackage", this.search)
        .then(() => {
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  created() {
    if (!moduleReservedPackage.isRegistered) {
      this.$store.registerModule("ReservatedPackage", moduleReservedPackage);
      moduleReservedPackage.isRegistered = true;
    }
    this.viewHistory=true;
      debugger;
    const ID = this.$store.state.AppActiveUser.Patient.ID;
    if (ID != undefined) {
      this.getReservatedPackages(ID);
    }
  },
};
</script>
<style>
#InCS table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
}

#InCS td,
#InCS th {
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
}

#InCS th {
  border-top: none;
}

#InCS th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}
#InCS th:last-child {
  border-top-right-radius: 36px;
}
#InCS td:first-child,
#InCS th:first-child {
  border-left: none;
}
</style>
