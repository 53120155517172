<template>
  <div id="InCS" class="flex items-center justify-center inset-0 ...">
    <table
      aria-describedby="Future Surgeries"
      style="width: 70%; background-color: white;margin: auto!important"
      class="text-center shadow-md w-2/3 m-3 bg-white"
    >
      <tr class="HeaderTableColor">
        <th id="FutureSurgeries" class="  h-32 text-center">
          {{ $t("FutureSurgeries") }}
        </th>
         <th class="  h-32 text-center">
            {{ $t("HospitalDate") }}
        </th>
        <th class="  h-32 text-center">
          {{ $t("DateTo") }}
        </th>
        <th class="  h-32 text-center">
          {{ $t("DateFrom") }}
        </th>
        <th class="  h-32 text-center">{{ $t("Status") }}</th>
        <th class="  h-32 text-center">
          <div class="MyPopClose">
            <feather-icon
              icon="XIcon"
              svgClasses="w-7 h-7 hover:text-primary stroke-current"
              @click.stop="$emit('closePop')"
            />
          </div>
          {{ $t("Doctor") }}
        </th> 
      </tr>
      <tr
        class="text-xl h-32"
        v-for="(item, index) in PatientReservationSurgeries"
        :key="index"
      >
        <td>
          <div @click="GoTOSurgeryProfile(item.ID)">
            <p v-if="item.HospitalSurgery">
              <u class="text-primary text-xl" style="cursor: pointer">{{
                item.HospitalSurgery.Surgery.Name
              }}</u>
            </p>
          </div>
        </td>
         <td>
          <p v-if="item.SurgeryDate">
            {{ item.SurgeryDate.split("T")[0] }}
          </p>
            <p v-else>{{$t("NotSetYet")}}</p>
        </td>
        <td>
          <p v-if="item.SurgeryDateTo">
            {{ item.SurgeryDateTo.split("T")[0] }}
          </p>
        </td>
        <td>
          <p v-if="item.SurgeryDateFrom">
            {{ item.SurgeryDateFrom.split("T")[0] }}
          </p>
        </td>
        <td>
          <p v-if="item.Status">{{ item.Status.Name }}</p>
        </td>

        <td>
          <div v-if="item.Doctor">
              <div>
                <imageLazy
                      :imageStyle="{ 'border-radius': '40px','width':'80px','height':'80px',size:'100px' }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + item.Doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
                
              </div>
              <p v-if="item.Doctor">
                {{ item.Doctor.Name }}
              </p>
          </div>
          <p v-else>{{$t("NotSetYet")}}</p>
        </td>
      </tr>
     <tr v-if="PatientReservationSurgeries.length==0">
        <td></td>            
        <td><h3  class="w-full sm:w-auto text-center text-primary mt-5 mb-5">{{$t("NoDataToshow")}}</h3></td>
        <td></td>
          </tr>
      <tr>
        <vs-button
          class="mr-2 mt-5 mb-5 customizer-btn rounded-lg text-sm"
          style="width: 95% !important; margin-left: 80%"
          icon-pack="feather"
          @click="getOldSurgeriesTreatments()"
          icon="icon-arrow-right"
          :icon-after="true"
        >
          {{ $t("History") }}</vs-button
        >
      </tr>
    </table>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components: {},
  data() {
    return {
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
      ShowOldSurgeries: false,
      Model: [],
    };
  },
  computed: {
    PatientReservationSurgeries() {
      return this.$store.state.patientList.PatientReservationSurgeries;
    },
  },
  methods: {
    GoTOSurgeryProfile(ID) {
      this.$router.push({ name: "Surgeryprofile", params: { ID: ID } });
    },
    getOldSurgeriesTreatments() {
      this.$emit("closeSidebar");
    },
    getPatientReservationSurgery(id) {
      var model = {};
      model.PatientId = id;
      model.StatusIds = [1,2]; //confirmed
      this.$vs.loading();

      this.$store
        .dispatch("patientList/getPatientReservationSurgery", model)
        .then(() => {
          this.$vs.loading.close();
               if(this.PatientReservationSurgeries==null||this.PatientReservationSurgeries.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },
  },
  components:{
    imageLazy
  },

  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    // const ID = this.$route.params.ID;
    const ID = this.$store.state.AppActiveUser.Patient.ID;
    if (ID != undefined) {
      debugger;
      this.getPatientReservationSurgery(ID);
    }
  },
};
</script>
<style>
#InCS table {
  border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
}

#InCS td,
#InCS th {
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
}

#InCS th {
  border-top: none;
}

#InCS th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}
#InCS th:last-child {
  border-top-right-radius: 36px;
}
#InCS td:first-child,
#InCS th:first-child {
  border-left: none;
}
</style>
