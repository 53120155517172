<template >
 <!-- v-if="$vs.rtl" -->
  <div class="w-full " >

<div >
      <homecard
        :title="$t('MedicalHistory')"
        :image="`${require('@/assets/images/Patient/GroupN9.png')}`"
      >
        <vs-button
          class="customizer-btn  text-sm mt-2 ml-10 mr-10"
          style=" width:95%; white-space: nowrap; l"
          @click="UpdateMedicalHistory()"
          >{{ $t("UpdateMedicalHistory") }}</vs-button
        >
        <vs-button
          class="customizer-btn  text-sm mt-3 ml-10 mr-10"
          style=" width:95%; white-space: nowrap; l"
          @click="ShowPatientMedicalReport = true"
          >{{ $t("YourMedicalReports") }}</vs-button
        >
      </homecard>

      <homecard
        :title="$t('PendingTask')"
        :image="`${require('@/assets/images/Patient/GroupN10.png')}`"
      >
        <vs-button
          class="customizer-btn  text-sm mt-2 ml-10 mr-10"
          style=" width:95%; white-space: nowrap; l"
          @click="getPendingDocuments()"
          >{{ $t("PendingDocument") }}</vs-button
        >
        <vs-button
          class="customizer-btn  text-sm mt-2 ml-10 mr-10"
          style="width:95%;white-space: nowrap;"
          @click="$router.push({ name: 'PatientPaymentPending' })"
          >{{ $t("PendingPayments") }}</vs-button
        >
      </homecard>

      <homecard
        :title="$t('Surgery')"
        :image="`${require('@/assets/images/Patient/GroupN14.png')}`"
      >
        <vs-button
          class="customizer-btn ml-10 mr-10 text-sm mt-2"
          style="width:95%!important ;white-space: nowrap;"
          @click="getSurgeriesTreatments()"
          >{{ $t("SurgeriesTreatments") }}</vs-button
        >
        <vs-button
          class="customizer-btn ml-10 mr-10 text-sm mt-2"
          style="width:95%!important; white-space: nowrap;"
          @click="showReservedPackages = true"
          >{{ $t("Packages") }}</vs-button
        >
      </homecard>

      <homecard

        :title="$t('OnlineConsultations')"
        :image="`${require('@/assets/images/Patient/GroupN13.png')}`"
      >
        <vs-button
          class="customizer-btn ml-10 mr-10 text-sm mt-2"
          style="width:95%!important; white-space:nowrap;"
         @click.stop="OpenOnlineConsultations()"
          >{{ $t("YourOnlineConsultations") }}</vs-button
        >

        <vs-button
          class="customizer-btn ml-10 mr-10 text-sm mt-2 fontcolor"
          style="width:95%!important;white-space:nowrap; "
          @click="goToConsultationRequest()"
          >{{ $t("RequestOnlineConsultations") }}</vs-button
        >
      </homecard>

       <homecard
        :title="$t('Electornicprescription')"
        :image="`${require('@/assets/images/Patient/GroupN11.png')}`"
      >
        <vs-button
          class="customizer-btn ml-10 mr-10 text-sm mt-2"
          color="primary"
          style="width:95%!important;white-space: nowrap;"
          @click="showReservationSessionPrescriptions = true"
          >{{ $t("PrintYourPrescription") }}</vs-button
        >
      </homecard>

      <homecard

        :title="$t('RepeatMedicalOnlineConsultations')"
        :image="`${require('@/assets/images/Patient/GroupN12.png')}`"
      >
        <vs-button
          class="customizer-btn ml-10 mr-10  text-sm mt-2"
          style="width:95%!important;white-space: nowrap; "
          icon-pack="feather"
          icon="icon-arrow-right"
          :icon-after="true"
          @click="ShowPatientRepeatMedical = true"
        ></vs-button>
      </homecard>

</div> 
    <div v-if="false" style="padding-top:4rem">
      <!-- row1 -->
      <div class="vx-row ml-5">
        <!-- part1 -->
        <div style="" class="vx-row lg:w-4/13 text-center backgroundShadow">
          <div class="vx-col mb-5 lg:w-11/12">
            <h3 style="color: #454a62" class="mt-5 headStyle" >
              {{ $t("MedicalHistory") }}
            </h3>

            <vs-button
              class="customizer-btn rounded-lg text-sm mt-5"
              style=" width:70%; white-space: nowrap; l"
              @click="UpdateMedicalHistory()"
              >{{ $t("UpdateMedicalHistory") }}</vs-button>

            <vs-button
              class="customizer-btn rounded-lg text-sm mt-5"
              style=" width:70%; white-space: nowrap; l"
              @click="ShowPatientMedicalReport = true"
              >{{ $t("YourMedicalReports") }}</vs-button>

          </div>
        </div>

        <div style="margin-left: -3%; margin-right: -3%; position: relative">
          <img
            height="150px"
            width="180px"
            src="@/assets/images/Patient/GroupN1.png"
            alt=""
          />
        </div>
        <div
          style="margin-left: 3%; margin-right: 3%; position: relative"
        ></div>
        <div style="margin-left: -3%; margin-right: -3%; position: relative">
          <img
            height="150px"
            width="180px"
            src="@/assets/images/Patient/GroupN2.png"
            alt=""
          />
        </div>
        <div style="" class="vx-row lg:w-4/13 text-center backgroundShadow">
          <div class="vx-col mb-5 lg:w-11/12">
            <h3 style="color: #454a62" class="mt-2 headStyle">
              {{ $t("PendingTask") }}
            </h3>

            <vs-button
              class="customizer-btn rounded-lg text-sm mt-5"
              style=" width:80%; white-space: nowrap; l"
              @click="getPendingDocuments()"
              >{{ $t("PendingDocument") }}</vs-button
            >
            <vs-button
              class="customizer-btn rounded-lg text-sm mt-5"
               style="width: 80%"
          @click="$router.push({ name: 'PatientPaymentPending' })"              >{{ $t("PendingPayments") }}</vs-button
            >
          </div>
        </div>
      </div>

      <!-- ---------------------------- -->

      <!-- row2 -->
      <div class="vx-row w-full mt-5 ml-5">
        <!-- part1 -->
        <div class="vx-row lg:w-4/13 text-center backgroundShadow">
          <div class="vx-col mb-10 lg:w-11/12">
            <h3 style="color: #454a62" class="mt-10 headStyle">
              {{ $t("Electornicprescription") }}
            </h3>

            <vs-button
              class="customizer-btn rounded-lg text-sm mt-5"
              color="primary"
              style="width: 75% !important"
              @click="showReservationSessionPrescriptions = true"
              >{{ $t("PrintYourPrescription") }}</vs-button
            >
          </div>
        </div>
        <div style="margin-left: -3%; margin-right: -3%; position: relative">
          <img
            height="150px"
            width="180px"
            src="@/assets/images/Patient/GroupN3.png"
            alt=""
          />
        </div>
        <div
          style="margin-left: 3%; margin-right: 3%; position: relative"
        ></div>
        <div style="margin-left: -3%; margin-right: -3%; position: relative">
          <img
            height="150px"
            width="180px"
            src="@/assets/images/Patient/GroupN4.png"
            alt=""
          />
        </div>
        <div style="" class="vx-row lg:w-4/13 text-center backgroundShadow">
          <div class="vx-col mb-10 lg:w-11/12">
            <h3 style="color: #454a62" class="mt-10 headStyle">
              {{ $t("RepeatMedicalOnlineConsultations") }}
            </h3>

            <vs-button
              class="customizer-btn rounded-lg text-sm mt-5"
              style="
                width: 75% !important ;
                margin-left: 15%;
                margin-right: 15%;
              "
              icon-pack="feather"
              icon="icon-arrow-right"
              :icon-after="true"
              @click="ShowPatientRepeatMedical = true"
            ></vs-button>
          </div>
        </div>
      </div>

      <!-- row3 -->
      <div class="vx-row w-full mt-5 ml-5">
        <!-- part1 -->
        <div style="" class="vx-row lg:w-4/13 text-center backgroundShadow">
          <div class="vx-col mb-5 lg:w-11/12">
            <h3 style="color: #454a62" class="mt-2 headStyle">
              {{ $t("OnlineConsultations") }}
            </h3>

            <vs-button
              class="customizer-btn rounded-lg text-sm mt-5"
              style=" width:70%; white-space: nowrap; l"
              @click.stop="OpenOnlineConsultations()"
              >{{ $t("YourOnlineConsultations") }}</vs-button
            >

            <vs-button
              class="customizer-btn rounded-lg text-sm mt-5 fontcolor"
              style=" width:70%; white-space: nowrap; l"
              @click="goToConsultationRequest()"
              >{{ $t("RequestOnlineConsultations") }}</vs-button
            >
          </div>
        </div>

        <div style="margin-left: -3%; margin-right: -3%; position: relative">
          <img
            height="150px"
            width="180px"
            src="@/assets/images/Patient/GroupN5.png"
            alt=""
          />
        </div>
        <div
          style="margin-left: 3%; margin-right: 3%; position: relative"
        ></div>

        <div style="margin-left: -3%; margin-right: -3%; position: relative">
          <img
            height="150px"
            width="180px"
            src="@/assets/images/Patient/GroupN6.png"
            alt=""
          />
        </div>
        <div style="" class="vx-row lg:w-4/13 text-center backgroundShadow">
          <div class="vx-col mb-5 lg:w-11/12">
            <h3 style="color: #454a62" class="mt-2 headStyle">
              {{ $t("Surgery") }}
            </h3>
            <vs-button
              class="customizer-btn rounded-lg text-sm mt-5"
              style="
                width: 75% !important ;
                margin-left: 15%;
                margin-right: 15%;
              "
              @click="getSurgeriesTreatments()"
              >{{ $t("SurgeriesTreatments") }}</vs-button
            >
            <vs-button
              class="customizer-btn rounded-lg text-sm mt-5"
              style="
                width: 75% !important ;
                margin-left: 15%;
                margin-right: 15%;
              "
              @click="showReservedPackages = true"
              >{{ $t("Packages") }}</vs-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- popups -->
    <!--mkl your medical report-->
    <vs-popup fullscreen title="" :active.sync="showYourMedicalReportfield">
      <showYourMedicalReportCom
        :patientDoc="patientDoc"
        v-if="showYourMedicalReportfield"
        @closePop="showYourMedicalReportfield = false"
      ></showYourMedicalReportCom>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="showCancelDialog">
      <CancelSessionAlert
        v-if="showCancelDialog"
        @closePop="showCancelDialog = false"
        :data="CancelSessionModel"
      ></CancelSessionAlert>
    </vs-popup>

    <vs-popup
      fullscreen
      title=""
      :active.sync="showReservationSessionPrescriptions"
    >
      <ReservationSessionPrescriptions
        @closePop="showReservationSessionPrescriptions = false"
        v-if="showReservationSessionPrescriptions"
      ></ReservationSessionPrescriptions>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="ShowPatientMedicalReport">
      <PatientMedicalReport
        @closePop="ShowPatientMedicalReport = false"
        v-if="ShowPatientMedicalReport"
        @OpenMedicalUploadDocument="OpenMedicalUploadDocument"
      ></PatientMedicalReport>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="ShowPatientRepeatMedical">
      <PatientRepeatMedical
        @closePop="ShowPatientRepeatMedical = false"
        @showConfirmDialog="ViewChangeSession"
        v-if="ShowPatientRepeatMedical"
      ></PatientRepeatMedical>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="ShowInComingSurgeries">
      <InComingSurgeries
        @closeSidebar="closeInComingSurgeriesPopup"
        @closePop="ShowInComingSurgeries = false"
        v-if="ShowInComingSurgeries"
      ></InComingSurgeries>
    </vs-popup>



    <vs-popup fullscreen title="" :active.sync="showPendingMedicalReport">
      <pendingMedicalReport
        @OpenUploadDocument="OpenUploadDocument"
        v-if="showPendingMedicalReport"
        @closePop="showPendingMedicalReport = false"
      ></pendingMedicalReport>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="ShowOldSurgeries">
      <OldSurgeries
        v-if="ShowOldSurgeries"
        @closePop="ShowOldSurgeries = false"
      ></OldSurgeries>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="ShowUploadDocument">
      <UploadPendingMedicalReport
        @closePop="ShowUploadDocument = false"
        @openPendingDocument="getPendingDocuments"
        :documentModel="documentModel"
      ></UploadPendingMedicalReport>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="ShowPatientUploadDocument">
      <UploadPatientMedicalReport
        @closePop="ShowPatientUploadDocument = false"
        @openMedicalDocument="openMedicalDocument"
        :documentModel="documentModel"
      ></UploadPatientMedicalReport>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="showReservedPackages">
      <ReservedPackage
        @closePop="showReservedPackages = false"
        v-if="showReservedPackages"
      ></ReservedPackage>
    </vs-popup>

    <vs-popup title="" :active.sync="ShowJoinPopup">
      <JoinSessionAlertPopup
        :sessionModel="sessionModel"
        v-if="ShowJoinPopup"
        @closePop="ShowJoinPopup = false"
      ></JoinSessionAlertPopup>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="showEditSessionDialog">
      <EditSessionAppointmet
        v-if="showEditSessionDialog"
        @closePop="showEditSessionDialog = false"
        @SetNewSession="SetNewSession"
        :doctor="doctorModel"
        :editSessionModel="EditSessionModel"
      ></EditSessionAppointmet>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="showSetSession">
      <SetSession
        v-if="showSetSession"
        @closePop="closeSessionPop"
        :sessionModel="sessionModel"
      ></SetSession>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="showSetPayment">
      <SetPayment
        v-if="showSetPayment"
        @closePop="closeSetPaymentPop"
        @closePayNow="showSetPayment=false"
        :sessionModel="sessionModel"
        :updatedPatientSession="updatedPatientSession"
      ></SetPayment>
    </vs-popup>

    <vs-popup fullscreen title="" :active.sync="ShowPatientNote">
      <SetPatientNote
      :sessionModel="sessionModel"
      :documentModel="documentModel"
        v-if="ShowPatientNote"
        @closePop="ShowPatientNote = false; showSetPayment= true"
        :updatedPatientSession="updatedPatientSession"
      ></SetPatientNote>
    </vs-popup>
      <vs-popup title="" :active.sync="showPayLaterPop" v-if="showPayLaterPop">
      <SuccessPopup
        :Message="$t('paymentLaterHint')"
        @CloseSeccussPopup="ClosePayLaterSeccussPopup"
      ></SuccessPopup>
    </vs-popup>

  </div>


</template>

<script>
//mkl your medical report
import showYourMedicalReportCom from "@/views/patientService/pendingMedicalReport.vue";
import modulePatient from "@/store/Patient/modulePatient.js";
import ReservationSessionPrescriptions from "@/views/Patient/Reservation/ReservationSessionPrescriptions.vue";
import PatientMedicalReport from "@/views/Patient/PatientMedicalReport.vue";
import UploadPatientMedicalReport from "@/views/Patient/UploadPatientMedicalReport.vue";
import PatientRepeatMedical from "@/views/Patient/PatientRepeatMedical.vue";
import InComingSurgeries from "@/views/Patient/PatientSurgeries&Treatments/InComingSurgeries.vue";
import pendingMedicalReport from "@/views/patientService/pendingMedicalReport.vue";
import UploadPendingMedicalReport from "@/views/patientService/UploadPendingMedicalReport.vue";
import OldSurgeries from "@/views/Patient/PatientSurgeries&Treatments/OldSurgeries.vue";
import ReservedPackage from "@/views/Patient/Packages/packages.vue";
import CancelSessionAlert from "@/views/Patient/Reservation/CancelSessionAlert.vue";
import EditSessionAppointmet from "@/views/Patient/Reservation/EditSessionAppointmet.vue";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import homecard from "@/components/patient/homeCard.vue";
import SetSession from "@/views/appointment/search/SetSession.vue";
import SetPayment from "@/views/appointment/search/SetPayment.vue";
import SetPatientNote from "@/views/appointment/search/SetPatientNote.vue";
import JoinSessionAlertPopup from "@/views/Patient/Reservation/JoinSessionAlertPopup.vue";
import SuccessPopup from "@/views/pages/register/SuccessPopup.vue";
export default {
  components: {
    SuccessPopup,
    SetSession,
    SetPatientNote,
    SetPayment,
    CancelSessionAlert,
    showYourMedicalReportCom,
    PatientMedicalReport,
    ReservationSessionPrescriptions,
    PatientRepeatMedical,
    InComingSurgeries,
    pendingMedicalReport,
    OldSurgeries,
    JoinSessionAlertPopup,
    UploadPendingMedicalReport,
    UploadPatientMedicalReport,
    ReservedPackage,
    EditSessionAppointmet,
    homecard,
  },
  data() {
    return {
      showPayLaterPop:false,
      updatedPatientSession: {},
      sessionModel: {},
      showSetSession: false,
      showSetPayment: false,
      ShowPatientNote: false,
      showEditSessionDialog: false,
      CancelSessionModel: {},
      EditSessionModel: {},
      doctorModel: {},
      showCancelDialog: false,
      showYourMedicalReportfield: false,
      showReservedPackages: false,
      patientDoc: {},
      ShowJoinPopup: false,
      sessionModel: {},
      documentModel: {},
      showReservationSession: false,
      ShowPatientMedicalReport: false,
      ShowInComingSurgeries: false,
      ShowPatientRepeatMedical: false,
      showReservationSessionPrescriptions: false,
      showPendingMedicalReport: false,
      ShowPatientUploadDocument: false,
      ShowOldSurgeries: false,
      ShowUploadDocument: false,
      propBG1: {
        backgroundImage: `url(${require("@/assets/images/Patient/Group1.png")})`,
        backgroundSize: "100% 200px",

        backgroundRepeat: "no-repeat",
      },
      propBG2: {
        backgroundImage: `url(${require("@/assets/images/Patient/Group2.png")})`,
        backgroundSize: "100% 200px",
        backgroundRepeat: "no-repeat",
      },
      propBG3: {
        backgroundImage: `url(${require("@/assets/images/Patient/backgroundThird.png")})`,
        backgroundSize: "100% 200px",
        backgroundRepeat: "no-repeat",
      },
      propBG4: {
        backgroundImage: `url(${require("@/assets/images/Patient/backgroundFourth.png")})`,
        backgroundSize: "100% 200px",
        backgroundRepeat: "no-repeat",
      },
      propBG5: {
        backgroundImage: `url(${require("@/assets/images/Patient/backgroundFifth.png")})`,
        backgroundSize: "100% 200px",
        backgroundRepeat: "no-repeat",
      },
      propBG6: {
        backgroundImage: `url(${require("@/assets/images/Patient/backgroundSixth.png")})`,
        backgroundSize: "100% 200px",
        backgroundRepeat: "no-repeat",
      },
    };
  },

  methods: {
    OpenOnlineConsultations() {
      this.$router.push({ name: "PatientReservationSession" });
    },

    SetNewSession(sessionModel) {
      this.showSetSession = true;
      this.showEditSessionDialog = false;
      this.sessionModel = sessionModel;
    },
    closeSessionPop(updatedPatientSession) {
      debugger
      this.updatedPatientSession = updatedPatientSession;
      this.showSetSession = false;
      debugger;
      if (this.updatedPatientSession.ID > 0)
               this.ShowPatientNote = true;
    },

    ClosePayLaterSeccussPopup() {
      this.showPayLaterPop = false;

        this.$router.push({
              name: "BookingSummary",
              params: { Flag: 1, ID: this.updatedPatientSession.ID },
            });
    },
    closeSetPaymentPop(ispaymentNow) {
      this.showSetPayment = false;


     if (!ispaymentNow) {
        debugger
        //ispaymentNow
        this.showPayLaterPop = true;
      } else {
        this.showSetPayment = true;
          this.$router.push({
              name: "BookingSummary",
              params: { Flag: 1, ID: this.updatedPatientSession.ID },
            });
      }
    },
    ViewChangeSession(CancelSessionModel, doctorID, viewType) {
      debugger;
      if (doctorID > 0) {
        //doctorID
        this.EditSessionModel = CancelSessionModel;
        this.EditSessionModel.viewType = viewType;
        this.ViewEditSessionDialog(doctorID);
      } else {
        this.showCancelDialog = true;
        this.CancelSessionModel = CancelSessionModel;
      }
    },
    ViewEditSessionDialog(doctorID) {
      this.$vs.loading();
      this.$store.dispatch("DoctorList/GetDoctor", doctorID).then((res) => {
        this.doctorModel = res.data.Data;
      this.showEditSessionDialog = true;

        this.$vs.loading.close();
      });
      this.$vs.loading.close();
    },

    joinMeeting(url) {
      debugger;
      this.showReservationSession = false;
      this.sessionModel.JoinUrl = url;
      this.ShowJoinPopup = true;
    },
    showYourMedicalReport() {
      this.patientDoc.ID = this.$store.state.AppActiveUser.Patient.ID;
      debugger;
      this.showYourMedicalReportfield = true;
    },
    openMedicalDocument() {
      this.ShowPatientMedicalReport = true;
      this.ShowPatientUploadDocument = false;
    },
    OpenMedicalUploadDocument(data) {
      debugger;
      this.ShowPatientMedicalReport = false;
      this.ShowPatientUploadDocument = true;
      this.documentModel = data;
    },
    OpenUploadDocument(data) {
      this.ShowUploadDocument = true;
      this.showPendingMedicalReport = false;
      this.documentModel = data;
    },
    closeInComingSurgeriesPopup() {
      debugger;
      this.ShowInComingSurgeries = false;
      this.ShowOldSurgeries = true;
    },

    UpdateMedicalHistory() {
      var id = this.$store.state.AppActiveUser.Patient.ID;
      this.$router.push({ name: "PatientMedicalHistory", params: { ID: id } });
    },
    goToConsultationRequest() {
      this.$router.push({ name: "AppointmentSearch" });
    },
    getSurgeriesTreatments() {
    //  this.ShowInComingSurgeries = true;
     this.$router.push({ name: "PatientSurgeries" });
    },
    getPendingDocuments() {
       this.$router.push({ name: "pendingMedicalReport" });
    },
    SearchPatientReservationSessions() {
      this.showReservationSession = true;
    },
  },

  computed: {
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    if (!moduleDoctor.isRegistered) {
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }


  },
  mounted() {
    this.isMounted = true;
  },
};
</script>
<style scoped>
.backgroundShadow {
  background: #eeee;
  box-shadow: rgb(0 0 0 / 20%) 5px 5px 15px;
  border-radius: 40px;

  height: 90%;
}

.CardImage {
  background-repeat: no-repeat;
  
}

.headStyle {
  font-size: 17px;
 padding-right: 100px;
}
</style>

>


// <style lang="scss">
//  @import '@/assets/scss/vuexy/_variables.scss';
//   .fontcolor{
//     color:$warning
//   }
//
</style>
