<template>
  <div id="PRMReport" class="flex  items-center justify-center  inset-0 ...">
    <table
      style="background-color:white;"
      class="text-center shadow-md lg:w-1/2  w-full m-3 bg-white"
    >
      <tr class="HeaderTableColor">
        <th class="  h-24 text-center">{{ $t("Doctor") }}</th>
        <th class="  h-24 text-center">{{ $t("Date") }}</th>
        <th class="h-24 text-center downloadCell">
          <div class="MyPopClose">
                <feather-icon class="m-6"  icon="XIcon" svgClasses="w-10 h-10 hover:text-primary stroke-current" @click.stop="$emit('closePop')" />
            </div>
        </th>
      </tr>
      <tr
        :data="tr"
        :key="indextr"
        v-for="(tr, indextr) in Model"
        class="text-xl h-24 p-1 m-1 text-center">
        <td >
          <div class="vx-col">
            <div>
                 <imageLazy
                      :imageStyle="{ 'border-radius': '15px','width': '70px','height':'70px'}"
                      :imageClass="'rounded-full'"
                      :src="baseURL + tr.Doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
            </div>
          <div>
          <span> {{$t("Dr") }}. {{ tr.Doctor.Name }} </span>

          </div>
          </div>
          
        </td>
        <td>{{ tr.DoctorSession.DateFormatted }}</td>
        <td class="downloadCell" style="width:10%;background-color:primary">
          <div class="vx-col" @click="GotoAppointment(tr)">
            <feather-icon
              icon="RepeatIcon"
              svgClasses="w-5 h-5"

            ></feather-icon>
            <p style="color:white">{{ $t("Repeat") }}</p>
          </div>
        </td>
      </tr>
      <tr class="h-24  text-center">
        <td></td>
        <td></td>
        <td style="width:10%;"></td>
      </tr>
       <tr v-if="Model.length==0">
        <td></td>
            
        <td><h3  class="w-full sm:w-auto text-center text-primary mt-5 mb-5">{{$t("NoDataToshow")}}</h3></td>
        <td></td>

          </tr>
    </table>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import axios from "@/axios.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components:{
    imageLazy
  },

  data() {
    return {
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
      Model: [],
      ReportData: {}
    };
  },
  methods: {
    GotoAppointment(session) {
         this.$emit('closePop');
      this.$emit('showConfirmDialog',session,session.DoctorID,2)
      // debugger;
      // this.$router.push({
      //   name: "AppointmentSearch",
      //   params: { DoctorID: session.DoctorID }
      // });
    },
    SaveSetting() {
      this.$vs.loading();

      this.$store
        .dispatch("patientList/UpdateUserNotificationSetting", this.Model)
        .then(() => {
          window.showSuccess(res.data.message);
          this.$vs.loading.close();
        })
        .catch((err) => {
          window.showError(err.response.data.message)
          this.$vs.loading.close();
        });
    },
    successUpload() {
      this.$vs.notify({
        color: "success",
        title: "Upload Success",
        text: "Lorem ipsum dolor sit amet, consectetur"
      });
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then(response => {
          this.ReportData.attachPath = response.data;
        });
        this.ShowAddReportPopup = true;
      }
    }
  },
  created() {
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    var Obj = {
      patientID: this.$store.state.AppActiveUser.Patient.ID,
     
    };
    debugger;
          this.$vs.loading();
    this.$store
      .dispatch("patientList/GetPatientRedervedDoctor", Obj)
      .then(res => {
        if (res.status == 200) {
          this.Model = res.data.Data;
          this.$vs.loading.close();

         if(this.Model==null||this.Model.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        }
      })
      .catch(() => {
        window.showError();
      });
  }
};
</script>
<style>
#PRMReport .downloadCell {
  min-width: 50px;
  max-width: 60px;
  padding: 0px;
}
#PRMReport table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
}
#PRMReport th,
#PRMReport td {
  border: 1px solid rgb(255, 255, 255);
  border-collapse: collapse;
}
#PRMReport .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
.login-tabs-container {
  min-height: 505px;
}
.con-tab {
  padding-bottom: 14px;
}

.con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}
#PRMReport .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
.head {
  color: white;
  text-align: center;
  margin-top: 20px;
}

[dir] #PRMReport th,
[dir] #PRMReport td {
  border: 1px solid #cfcfcf !important;
}

</style>
