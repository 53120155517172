<template>
  <div id="patientMReport" class=" items-center justify-center inset-0 ..." style="">

      <table
         style="background: white;margin: auto!important"
         class="text-center shadow-md w-2/3 m-3"
        >
          <tr class="HeaderTableColor">
            <th class="  h-24 text-center">{{$t("TestsAndReports")}}</th>
            <th class="  h-24 text-center">{{$t("UploadDate")}}</th>
            <th class="  h-24 text-center">{{$t("Description")}}</th>

            <th class="  h-24 text-center">

            {{$t("Doctors")}}</th>
            <th>
                <div class="MyPopClose">
                  <feather-icon   icon="XIcon" svgClasses="w-7 h-7 hover:text-primary stroke-current" @click.stop="$emit('closePop')" />
               </div>
            </th>
          </tr>
          <tr
            :data="tr"
            :key="indextr"
            v-for="(tr, indextr) in Model"
            v-if="((paginationMaxNumber*(paginationPageNumber-1))<=indextr)&&(indextr<(paginationMaxNumber*paginationPageNumber))"
            class="text-xl h-24 p-1 m-1 text-center"
          >
            <td>
              <span>{{ tr.ReportTypeName }}</span>
            </td>
            <td><span  v-if="tr.Date">{{ tr.Date.split("T")[0] }}</span> </td>
            <td><span  >{{ tr.Description }}</span> </td>

            <td>
              <imageLazy
                      :imageStyle="{ 'border-radius': '20px','width': '70px','height':'70px'}"
                      :imageClass="'rounded-full'"
                      :src="baseURL + tr.DoctorImage"
                      placeHolderType="doctor"
                    />
             
              <p v-if="tr.DoctorName"> {{$t("Dr") }}.{{ tr.DoctorName }}</p>
            </td>
             <td>
                 <div class="m-3" v-if="tr.AttachPath!=null||tr.AttachPath!=undefined">
                <a :href="baseURL + tr.AttachPath" download target="_blank">
                  <vs-avatar
                    color="primary"
                    icon-pack="feather"
                    icon="icon-download"
                    style="background: primary"
                    class="-m-3"
                /></a>
                
                <p
                  style="
                    color: #ffffff;
                    font-size: 12px;
                    opacity: 1;
                    line-height: 30px;
                    "
                >
                  {{ $t("Download") }}
                </p>

                 <feather-icon
                        icon="TrashIcon"
                        svgClasses="w-5 h-5 hover:text-danger stroke-current"
                        class="m-2 "
                        @click.stop="$emit('closePop')"
                        @click="openConfirm(tr)"
                      />
              </div>
             </td>
          </tr>
           <tr v-if="Model.length==0">
        <td></td>

        <td><h3  class="w-full sm:w-auto text-center text-primary mt-5 mb-5">{{$t("NoDataToshow")}}</h3></td>
        <td></td>

          </tr>
          <tr class="h-24  text-center">
            <td></td>
            <td></td>
            <td style="margin:auto">


         <div class="vx-row  w-full  text-center " style="width:110% !important"  v-if="!$vs.rtl" >
           <div class="vx-col lg:w-1/3"></div>
              <feather-icon
                  v-if="paginationMaxNumber*(paginationPageNumber-1)<Model.length&&paginationPageNumber!=1"
                icon="ArrowLeftCircleIcon"
                :rtl="$vs.rtl"
                @click.stop="paginationPageNumber--"
                class="cursor-pointer vx-col lg:w-1/6 "
              ></feather-icon>
              <h2 style="font-weight:bold; vx-col lg:w-1/4">{{paginationPageNumber}}</h2>
                <feather-icon
                :rtl="$vs.rtl"
                v-if="paginationPageNumber*paginationMaxNumber<=Model.length"
                icon="ArrowRightCircleIcon"
                @click.stop="paginationPageNumber++"
                class="cursor-pointer vx-col lg:w-1/4"
              ></feather-icon>
             </div>
         <div class="vx-row  w-full" v-if="$vs.rtl" >
               <div class="vx-col lg:w-1/3 "></div>
                 <feather-icon
                 v-if="paginationMaxNumber*(paginationPageNumber-1)<Model.length&&paginationPageNumber!=1"
                    icon="ArrowRightCircleIcon"
                    :rtl="$vs.rtl"
                    @click.stop="paginationPageNumber--"
                    class="cursor-pointer  vx-col lg:w-1/6"
                  ></feather-icon>
                  <h2 style="font-weight:bold; " class=" vx-col lg:w-1/4">{{paginationPageNumber}}</h2>
                    <feather-icon
                    :rtl="$vs.rtl"
                    v-if="paginationPageNumber*paginationMaxNumber<=Model.length"
                    icon="ArrowLeftCircleIcon"
                    @click.stop="paginationPageNumber++"
                    class="cursor-pointer  vx-col lg:w-1/4"
                  ></feather-icon>
             </div>

              <div class="vx-row  w-full mt-4 ">
              <div class="vx-col lg:w-1/3 "></div>
             <vs-button

                @click="OpenMedicalUploadDocument"
                color="primary"
                type="filled"
                class=" ml-6 "

                >{{$t('UploadDocument')}}</vs-button
              >
              </div>
            </td>


            <td class=" text-center">


            </td>
            <td></td>
            <td></td>

          </tr>

          <tr style="height:60px">
          </tr>
        </table>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain, mainSetting } from "@/gloabelConstant.js";
import axios from "@/axios.js";
import moduleDocumentType from "@/store/settings/documentType/moduleDocumentType.js";
import moduleAnalysisType from "@/store/settings/analysisType/moduleAnalysisType.js";
import moduleReportType from "@/store/settings/reportType/moduleReportType.js";
import moduleScanType from "@/store/settings/scanType/moduleScanType.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  data() {
    return {
      paginationPageNumber:1,
      paginationMaxNumber : 5,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      baseURL: domain,
      Model: [],
      ReportData: {},
      ShowDocumentUpload:false,
      documentModel:{},
      rowDataForDelete:{},
    };
  },
   computed: {

    DocumentTypes() {
      return this.$store.state.DocumentTypeList.DocumentTypes;
    },
     ScanTypes() {
      return this.$store.state.ScanTypeList.ScanTypes;
    },
      ReportTypes() {
      return this.$store.state.ReportTypeList.ReportTypes;
    },
    AnalysisTypes() {
      return this.$store.state.AnalysisTypeList.AnalysisTypes;
    }
  },
  methods: {
      openConfirm(data) {
      this.rowDataForDelete = data;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: this.$t("DeleteConfirmText"),
        acceptText: this.$t("Delete"),
        cancelText: this.$t("Cancel"),
        accept: this.acceptAlert
      });
    },
     acceptAlert() {
      this.deleteData(this.rowDataForDelete);
      this.$vs.notify({
        color: "success",
        title: "Deleted record",
        text: this.$t("DeletedSuccessfully")
      });
    },
     deleteData(data) {
      this.$store.dispatch("patientList/DeletePatientMedicalDocument", data).then(res=>{
        if(res.status==200){
          const ItemIndex = this.Model.findIndex(
        p => p.ID == data.ID && p.DocumentID == data.DocumentID
      );
        this.Model.splice(ItemIndex, 1);
        }
      }).catch(err => {
        console.error(err);
      });
    },
    OpenMedicalUploadDocument()
    {
      this.$emit("OpenMedicalUploadDocument",{});
    },

    getModel(){
    this.$store
      .dispatch("patientList/GetAllPatientMedicalReports", this.$store.state.AppActiveUser.Patient.ID)
      .then(res => {
        if (res.status == 200) {
          this.Model = res.data.Data;
              if(this.Model==null||this.Model.length==0){
             this.$vs.notify({
          title: this.$t("NoData"),
          text: this.$t("NoDataToshow"),
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "warning"
        });
        }
        }
      })
      .catch(() => {
        window.showError();
      });
    },

    AddAttachmnet(){
      debugger
      this.documentModel.PatientID = this.$store.state.AppActiveUser.Patient.ID;
      this.$store
      .dispatch("patientList/AddPatientMedicalDocument", this.documentModel)
      .then(res => {
        if (res.status == 200) {
          window.showSuccess(res.data.message);
          this.getModel();
        }
      })
      .catch((err) => {
     window.showError(err.response.data.message)
      });
      this.documentModel = {};
    },
    successUpload(event) {
      this.documentModel.AttachPath = event.target.responseText;
      window.showAddSuccess();
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        let data = new FormData();
        data.append("file", input.target.files[0]);

        axios.post(this.uploadUrl, data,{
          headers: {
              "Content-Type": "multipart/form-data"
            }
        }).then(response => {
          this.ReportData.attachPath = response.data;
        });
        this.ShowAddReportPopup = true;
      }
    }
  },
  created() {
    if (!moduleScanType.isRegistered) {
      this.$store.registerModule("ScanTypeList", moduleScanType);
      moduleScanType.isRegistered = true;
    }

    this.$store.dispatch("ScanTypeList/GetAllScanTypes");
    if (!moduleReportType.isRegistered) {
      this.$store.registerModule("ReportTypeList", moduleReportType);
      moduleReportType.isRegistered = true;
    }

    this.$store.dispatch("ReportTypeList/GetAllReportTypes");
    if (!moduleAnalysisType.isRegistered) {
      this.$store.registerModule("AnalysisTypeList", moduleAnalysisType);
      moduleAnalysisType.isRegistered = true;
    }

    this.$store.dispatch("AnalysisTypeList/GetAllAnalysisTypes");
      if (!moduleDocumentType.isRegistered) {
      this.$store.registerModule("DocumentTypeList", moduleDocumentType);
      moduleDocumentType.isRegistered = true;
    }

    this.$store.dispatch("DocumentTypeList/GetAllDocumentTypes");
    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }
    // var Obj = {
    //   patientID: this.$store.state.AppActiveUser.Patient.ID
    // };
    this.getModel();
  },
  components:{
    imageLazy
  },
};
</script>
<style>
#patientMReport table {
 border-collapse: collapse;
  border-radius: 36px !important;
  -moz-border-radius: 36px;
}
#patientMReport td,
#patientMReport th {
    border-collapse: collapse;
  border-left: solid #00000029 1px;
  border-top: solid #00000029 1px;
}

#patientMReport th:first-child {
  border-top-left-radius: 36px;
  border-left: none;
  border-right: none;
}
#patientMReport th:last-child {
  border-top-right-radius: 36px;
}


#patientMReport  .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
#patientMReport  .login-tabs-container {
  min-height: 505px;
}
#patientMReport  .con-tab {
  padding-bottom: 14px;
}

#patientMReport  .con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}
#patientMReport  .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
#patientMReport  .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}

</style>
