<template>
      <div  class="w-full text-center backgroundShadow2 p-2 mt-10">
        <vs-row class="w-full"   vs-align="flex-start" vs-justify="flex-start" vs-w="12" vs-type="flex">
         
          <vs-col class="text-right ml-5 mr-5 "  vs-sm="4"  vs-w="1" vs-algin="right" vs-justify="right">
              <img
                height="100px"
                width="120px"
                :src="image"
                alt=""
              />
          </vs-col>
          <vs-col   vs-algin="right"  vs-offset="1" vs-justify="right" vs-sm="7" vs-w="3" class="text-left ml-5 mr-5">
            <ul>
               <li>
                  <h2 style="color: #454a62" class="ml-10 mr-10" >
                    {{ title }}
                  </h2>
               </li>
               <li>
                  <slot></slot>
               </li>
            </ul>
           
              
          </vs-col>
           <vs-col  vs-w="7" vs-sm="0">
          </vs-col>
        </vs-row>
      
      </div>
</template>
<script>
export default {
    props:{
        title:{
              type: String,
             required: true,
             default:""
        },
         image:{
             type: String,
             required: true,
             default:""
        }
    }
}
</script>

<style scoped>
.backgroundShadow2 {
  background: #eeee;


  height: 90%;
}
</style>